/* eslint-disable import/no-cycle */

import type { ListQueryFilter, ListQuerySort } from '@phoenix-systems/react-table';
import type { Moment } from 'moment';

import type { SolrOperator, SortDirection } from '@dam/types';

export type DamUserAccount = {
  username: string;
  createdAt: string;
};

export enum DamInvestorStates {
  EMAIL_VERIFICATION_PENDING = 'EMAIL_VERIFICATION_PENDING',
  EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS',
  TERMS_NOT_ACCEPTED = 'TERMS_NOT_ACCEPTED',
  TERMS_ACCEPTED = 'TERMS_ACCEPTED',
  KYC_PENDING = 'KYC_PENDING',
  KYC_FAILED = 'KYC_FAILED',
  KYC_SUCCESS = 'KYC_SUCCESS',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
}

export type DamInvestorStatesUnion = keyof typeof DamInvestorStates;

export enum DAM_GENDER {
  M = 'M',
  F = 'F'
}

export type DamUser = {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: DAM_GENDER;
  status: DamInvestorStatesUnion;
  registeredAt: string;
  birthDate: string | Moment;
  nationality: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  corporateEntityName: string;
  corporateEntityCountry: string;
  accounts?: DamUserAccount[];
  kycCode: string;
  kycProcessId: string;
  mwId: string;
  isOnSmartContract: boolean;
  onSmartContract: boolean;
};

export type DamUpdateUserPayload = {
  email: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: DAM_GENDER | null;
  birthDate?: string | null;
  nationality?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
};

export type DamUserField = 'firstName' | 'lastName' | 'middleName' | 'status' | 'country';

export type DamUserFielQueried =
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'status'
  | 'country'
  | 'fullName';

export type DamSortField =
  | 'registeredAt'
  | 'email'
  | 'status'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'country'
  | 'kyc'
  | 'kycStatus';

export type DamSort = {
  fl: DamSortField;
  dir: SortDirection;
};

export type DamFilter = {
  fl: DamUserField;
  val: string[];
  op?: SolrOperator;
};

export type DamFilterQueried = {
  field: string;
  value: Array<any> | [];
  operator?: SolrOperator;
};

export type DamUserSearchRequest = {
  pagination: {
    currentPage: number;
    pageSize: number;
  };
  operator: SolrOperator | '';
  sort: {
    field: DamSortField;
    direction: SortDirection;
  };
  filters: DamFilterQueried[];
};

export type DamGetUserByEmail = {
  email: string;
  type?: string;
};

export type DamUsersResponse = {
  data: DamUser[];
  index: number;
  size: number;
  total: number;
};

export type DamUserStatusResponse = {
  email: string;
  status: DamInvestorStatesUnion;
};

export type DamUserStatusPayload = {
  email: string;
  status: DamInvestorStatesUnion;
};

export type DamUserRevertKycFailedPayload = {
  email: string;
};

export type DamResetPasswordPayload = {
  password: string;
  email: string;
};

export type DamGetInvestorsPayload = {
  pageSize?: number;
  currentPage?: number;
  sort?: ListQuerySort[];
  filter?: ListQueryFilter[];
};

export type DamUpdateKeycloakIdPayload = {
  email: string;
  id: string;
};

export type DamUpdateKeycloakIdResponse = {
  email: string;
  keycloakId: string;
};
