import { keys } from 'lodash';

import { DamInvestorStates } from 'services/api/domain/damUser';

export const BASE_PATH = 'admin';

export const DAM_INVESTOR_STATES_ARRAY = keys(DamInvestorStates);

export const COUNTRIES = [
  'aus',
  'bel',
  'bgr',
  'chl',
  'dnk',
  'deu',
  'est',
  'fin',
  'grc',
  'gbr',
  'irl',
  'isl',
  'isr',
  'ita',
  'jpn',
  'can',
  'hrv',
  'lie',
  'ltu',
  'lux',
  'mlt',
  'mex',
  'nld',
  'nor',
  'aut',
  'pol',
  'prt',
  'rou',
  'swe',
  'che',
  'svk',
  'svn',
  'esp',
  'cze',
  'tur',
  'hun',
  'cyp'
];

export const UNSUPPORTED_COUNTRIES = [
  'AF',
  'AL',
  'AS',
  'BB',
  'BY',
  'BW',
  'BI',
  'KH',
  'CA',
  'CF',
  'CG',
  'CU',
  'ER',
  'GQ',
  'TD',
  'GH',
  'GW',
  'HT',
  'IQ',
  'IR',
  'JM',
  'KP',
  'LA',
  'LB',
  'LR',
  'LY',
  'ML',
  'MU',
  'MN',
  'MM',
  'NI',
  'PK',
  'PW',
  'PA',
  'RU',
  'SO',
  'SD',
  'SS',
  'WS',
  'SL',
  'SY',
  'TT',
  'UG',
  'US',
  'VU',
  'VE',
  'ZW',
  'VI',
  'YE'
];

export const TOP_COUNTRIES = ['che', 'deu', 'aut', 'lie', 'nld'];

export const USER_ACCOUNT_SALES_FILTER = {
  START: 'START',
  END: 'END',
  RANGE: 'RANGE'
};

export const ERROR_CODES = {
  SERVER_ERROR: '500',
  NON_AUTH_ERROR: '401'
};
