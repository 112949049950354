/* eslint-disable consistent-return */
import React, { useMemo } from 'react';

import { keys } from 'lodash';
import type { RouteObject } from 'react-router';
import { Navigate, useRoutes } from 'react-router';

import { getRoute, Language } from '@dam/shared';
import type { RoutePath } from '@dam/types';

import HealthPage from 'components/pages/healthPage';
import NotFoundPage from 'components/pages/notFoundPage';
import Page from 'components/ui/page';
import { languageConfig } from 'services/i18n/language.config';
import { pathParams, routes } from 'services/router/routes';


const Router: React.FC = () => {
  const getConfig = useMemo(
    () => () => {
      const walk = (path: RoutePath): RouteObject => {
        const routeConfig: RouteObject = {
          path: path._segment,
          element: path._element
        };

        const children: RouteObject[] = [];

        if (path._index) {
          children.push({
            index: true,
            element: path._index
          });
        }

        keys(path).forEach(key => {
          if (key !== '_path' && key !== '_element' && key !== '_segment' && key !== '_index') {
            const res = walk((path as any)[key]);
            children?.push(res);
          }
        });

        routeConfig.children = children.length ? children : undefined;

        return routeConfig;
      };

      const children: RouteObject[] = [];

      keys(routes).forEach(key => {
        const res = walk((routes as any)[key]);
        children.push(res);
      });

      const config: RouteObject[] = [
        {
          //   element: <Page />,
          children: [
            {
              path: '/',
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to={getRoute(routes.investors._path, {
                        query: { language: languageConfig.defaultLocale }
                      })}
                    />
                  )
                },
                {
                  path: pathParams.language,
                  element: (
                    <Language routes={routes} defaultLocale={languageConfig.defaultLocale} />
                  ),
                  children: [
                    {
                      path: 'health',
                      element: <HealthPage />
                    },
                    {
                      //   path: '*',
                      element: <Page />,
                      children: [...children, { path: '*', element: <NotFoundPage /> }]
                    }
                  ]
                }
              ]
            },
            {
              path: pathParams.language,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to={getRoute(routes.investors._path, {
                        query: { language: languageConfig.defaultLocale }
                      })}
                    />
                  )
                }
              ]
            }
          ]
        }
      ];

      return config;
    },
    []
  );

  const element = useRoutes(getConfig());

  return element;
};

export default Router;
