/**
 * Do not edit this file. its generically produced form js modules
 */
import type { AppStylesConfig } from '@dam/types';

export const appStyles: AppStylesConfig = {
  colors: {
    'primary-dark': '#bb4714',
    'primary': 'rgb(var(--color-primary))',
    'primary-light': '#f29b75',
    'primary-bg': 'rgb(var(--color-primary-bg))',

    'neutral-0': '#FFFFFF',
    'neutral-100': '#f2f2f2',
    'neutral-200': '#ebebeb',
    'neutral-300': '#d7d7d7',
    'neutral-400': '#a9a9a9',
    'neutral-500': '#919191',
    'neutral-600': '#797979',
    'neutral-700': '#5A5A5A',
    'neutral-800': '#464646',
    'neutral-900': '#000000',

    'success-dark': '#007936',
    'success': '#009744',
    'success-light': '#00bd55',
    'success-bg': '#e5f4ec',

    'error-dark': '#a62219',
    'error': '#d02b20',
    'error-light': '#d9554c',
    'error-bg': '#fff5f4',

    'warning-dark': '#d9b53a',
    'warning': '#EFBD11',
    'warning-light': '#f7df8d',
    'warning-bg': '#fdf8e7',

    'info-dark': '#1c225a',
    'info': '#4755e3',
    'info-light': '#9099ee',
    'info-bg': '#eceefc',

    'mark': '#fff79f',
    'brand-gray': '#9e9e9b',
    'brand-black': '#333333'
  },
  htmlFontSize: 10,
  defaultFontSize: 14,
  fonts: {
    // sans: ["'Open Sans'", 'sans-serif'
    sans: ["'Inter'", 'sans-serif']
  },
  breakpoints: {
    xs: 480,
    sm: 576,
    md: 1000,
    lg: 1200,
    xl: 1400,
    xxl: 1600
  }
};
