/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */

import type { TableSearchQueryParams } from '@arpia/react-table/dist/src/types';
import { omit } from 'lodash';

import type { SortDirection } from '@dam/types';

import { BASE_PATH } from 'consts';
import apiClient from 'services/api/apiClient';

import type {
  DamGetUserByEmail,
  DamUser,
  DamUserStatusResponse,
  DamUserStatusPayload,
  DamUserRevertKycFailedPayload,
  DamResetPasswordPayload,
  DamUserSearchRequest,
  DamGetInvestorsPayload,
  DamUsersResponse,
  DamUpdateKeycloakIdPayload,
  DamUpdateKeycloakIdResponse,
  DamSortField,
  DamUpdateUserPayload
} from './damUser.types';

/**
 * GET /api/admin/investors
 */
export const api_damGetAllUsers = async (
  params: DamGetInvestorsPayload
): Promise<DamUsersResponse> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/investors`, {
    params: {
      index: params.currentPage && params.currentPage > 0 ? params.currentPage - 1 : 0,
      size: Number(params.pageSize) || 20
    }
  });

  return data;
};

/**
 * POST /api/admin/investors
 */
export const api_damPostUsers = async (params: TableSearchQueryParams): Promise<any> => {
  const normalizeField = (field: string) => (field === 'kycStatus' ? 'kyc' : field);

  const createFilter = (field: string, values: any, operator = 'OR') => ({
    field: normalizeField(field),
    value: [...values],
    operator
  });

  const createSearchFilter = (searchTerm: string) => ({
    field: 'fullName',
    value: [searchTerm]
  });

  const filters = [];

  if (params.filters) {
    filters.push(...params.filters.map(fl => createFilter(fl.field, fl.values)));
  }

  if (params.searchTerm) {
    filters.push(createSearchFilter(params.searchTerm));
  }

  const sortHelper = () => {
    if (params.sort && params.sort.length > 0) {
      if (params.sort[0].field === 'kycStatus') {
        return 'kyc';
      }

      return params.sort[0].field;
    }

    return 'registeredAt';
  };

  const searchPayload: DamUserSearchRequest = {
    pagination: {
      currentPage: params.pagination.page ? params.pagination.page - 1 : 0,
      pageSize: params.pagination.limit as number
    },
    operator: 'AND',
    sort: {
      field: sortHelper() as DamSortField,
      direction:
        params.sort && params.sort.length > 0
          ? (params.sort[0].direction.toLowerCase() as SortDirection)
          : 'desc'
    },
    filters: filters as any
  };

  const payloadHandler = () => {
    if (!params.searchTerm && !params.filters && !params.sort) {
      const paginationOnlysearchPayload = omit(searchPayload, ['operator', 'sort', 'filters']);

      return paginationOnlysearchPayload;
    }

    if (params.sort && params.sort.length > 0) {
      if (params.filters || params.searchTerm) {
        return searchPayload;
      }
      const sortSearchPayload = omit(searchPayload, ['operator', 'filters']);

      return sortSearchPayload;
    }

    return searchPayload;
  };

  const { data } = await apiClient.post(`/${BASE_PATH}/investors`, {
    ...payloadHandler()
  });

  return data;
};

/**
 * GET /api/admin/investors/{email}
 */
export const api_damGetUserByEmail = async (params: DamGetUserByEmail): Promise<DamUser> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/investors/${params.email}`);

  return data;
};

/**
 *
 * PUT /api/admin/investors/{email}/keycloak
 */
export const api_damUpdateInvestorKeycloackId = async (
  payload: DamUpdateKeycloakIdPayload
): Promise<DamUpdateKeycloakIdResponse> => {
  const modifiedPayload = omit(payload, ['email']);
  const { data } = await apiClient.put(`/${BASE_PATH}/investors/${payload.email}`, {
    ...modifiedPayload
  });

  return data;
};

/**
 * DELETE /api/admin/investors/{email}/kyc
 */
export const api_damDeleteKycFailedState = async (
  payload: DamUserRevertKycFailedPayload
): Promise<DamUserStatusResponse> => {
  const { data } = await apiClient.delete(`/${BASE_PATH}/investors/${payload.email}/kyc`);

  return data;
};

/**
 * PUT /api/admin/investors/${email}/password
 */

export const api_damPutResetPassword = async (payload: DamResetPasswordPayload) => {
  const data = await apiClient.put(`/${BASE_PATH}/investors/${payload.email}/password`, {
    password: payload.password
  });

  return data;
};

/**
 * PUT /api/admin/investors/{email}/status
 */
export const api_damOverwriteUserStatus = async (
  payload: DamUserStatusPayload
): Promise<DamUserStatusResponse> => {
  const { data } = await apiClient.put(`/${BASE_PATH}/investors/${payload.email}/status`, {
    status: payload.status
  });

  return data;
};

/**
 *
 * PUT /api/admin/investors/investors/${email}
 */
export const api_damUpdateInvestor = async (payload: DamUpdateUserPayload): Promise<DamUser> => {
  const modifiedPayload = omit(payload, ['email']);
  const { data } = await apiClient.put(`/${BASE_PATH}/investors/${payload.email}`, {
    ...modifiedPayload
  });

  return data;
};
