import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ContainerProvider, useIsEnv } from '@dam/shared';

import queryClient from 'services/api/queryClient';
import intiI18n from 'services/i18n/i18n.init';
import { useAuthStore } from 'services/store/auth';

import keycloak from '../../../keycloak';
import AppAuthProvider from '../appAuthProvider';
import ErrorHandleProvider from '../errorHandleProvider';
import Router from '../router';

const App: React.FC = () => {
  const isLocalEnv = useIsEnv('local');
  const setIsLoggingOut = useAuthStore(st => st.setIsLoggingOut);

  const onKeycloakEvent = (event: any, error: any) => {
    if (window._env_.ENVIRONMENT !== 'prod') {
      switch (event) {
        case 'onAuthSuccess':
          // eslint-disable-next-line no-console
          setIsLoggingOut('false');
          break;
        case 'onAuthLogout':
          // eslint-disable-next-line no-console
          console.log('LOGOUT', event, error);
          break;
        default:
          break;
      }
    }
  };

  const onKeycloakTokens = (token: any) => {
    window._env_.ACCESS_TOKEN = token?.token as string;
  };

  useEffect(() => {
    intiI18n();
  }, []);

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={{
            checkLoginIframe: true,
            checkLoginIframeInterval: 10,
            onLoad: 'check-sso'
          }}
          onEvent={onKeycloakEvent}
          onTokens={onKeycloakTokens}
        >
          <QueryClientProvider client={queryClient}>
            {/* @ts-ignore */}
            <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
              <AppAuthProvider>
                <ErrorHandleProvider>
                  <ContainerProvider>
                    <Router />
                  </ContainerProvider>
                  {isLocalEnv && <ReactQueryDevtools position="bottom-left" />}
                  <ToastContainer
                    autoClose={4000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                  />
                </ErrorHandleProvider>
              </AppAuthProvider>
            </I18nProvider>
          </QueryClientProvider>
        </ReactKeycloakProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default App;
