import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { toast } from 'react-toastify';

import { useApiErrorStore } from 'services/store/apiError';

import { NotAuthError, ServerError } from '../error';

/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */

const { setErrorType } = useApiErrorStore.getState();

const beforeRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (!config.headers) {
    config.headers = {};
  }

  const token = window._env_.ACCESS_TOKEN;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
    config.headers['Content-Type'] = 'application/json';

    if (config.data && typeof config.data === 'object') {
      config.data = JSON.stringify(config.data);
    }
  }

  return config;
};

/**
 * business api
 */
const apiClient = axios.create({
  baseURL: window._env_.BUSINESS_API_URL
});

apiClient.interceptors.response.use(
  (res: any) => res,
  (err: any) => {
    if (err.response.status === 400) {
      toast(err.response.data.error, {
        type: 'error'
      });
    }

    if (err.response.status === 401) {
      setErrorType('401');
      throw new NotAuthError(err.response.status);
    }

    if (err.response.status >= 500) {
      setErrorType('500');
      throw new ServerError(err.response.status);
    }

    return Promise.reject(err);
  }
);

apiClient.interceptors.request.use(config => beforeRequest(config));

export default apiClient;
