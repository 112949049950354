import create from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import type { ErrorCode } from './apiError.types';

type ApiErrorState = {
  errorMessage: string;
  setErrorType: (payload: ErrorCode) => void;
  reset: () => void;
};

const devToolsName = 'API_ERROR_STORE';

export const useApiErrorStore = create<ApiErrorState>()(
  devtools(
    subscribeWithSelector(set => ({
      errorMessage: '',
      setErrorType: (payload: ErrorCode) => set(() => ({ errorMessage: payload })),
      reset: () => set({ errorMessage: '' })
    })),
    {
      name: devToolsName
    }
  )
);
