/* eslint-disable import/prefer-default-export */
import tw, { css, styled } from 'twin.macro';

import { getRem } from '@dam/shared';

const DEFAULT_MIN_WIDTH = 160;

type StyledMenuProps = {
  $minWidth?: number;
};

export const StyledMenu = styled.div<StyledMenuProps>(({ $minWidth }) => [
  css`
    ${tw`min-w-120`};
    min-width: ${getRem($minWidth || DEFAULT_MIN_WIDTH)};

    & .ant-menu-item {
      ${tw`border-b border-neutral-200 hover:text-primary hover:bg-neutral-100 text-15`};

      &:last-of-type {
        ${tw`border-none`};
      }

      & .active-icon {
        ${tw`invisible`};
      }

      &.active {
        ${tw`text-neutral-500`};

        & .active-icon {
          ${tw`visible`};
        }
      }
    }
  `
]);
