import { useCallback, useEffect } from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { toast } from 'react-toastify';
import { useCopyToClipboard as useCp } from 'react-use';

type CopyToClipboardHandler = (copyValue?: string, message?: string) => void;

export const useCopyToClipboard = (): CopyToClipboardHandler => {
  const [{ error }, copyToClipboard] = useCp();

  const handleNotify = useCallback(
    (copyValue?: string, message?: string) => {
      if (copyValue) {
        copyToClipboard(copyValue);

        toast(message || i18n._(t`Copied value "${copyValue}" to clipboard`), {
          type: 'info'
        });
      }
    },
    [copyToClipboard]
  );

  useEffect(() => {
    if (error) {
      toast(i18n._(t`Failed to copy value.`), {
        type: 'error'
      });
    }
  }, [error]);

  return handleNotify;
};
