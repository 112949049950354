/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import clsx from 'clsx';
import { omit } from 'lodash';

import { useGoto } from '@dam/shared';

type DropdownMenuItemProps = {
  id: string;
  icon?: IconDefinition;
  label?: string | React.ReactNode;
  route?: string;
  onClick?: () => void;
  isActiveIcon?: boolean;
  minWidth?: number;
  className?: string;
  isActive?: boolean;
  isDisabled?: boolean;
};

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = props => {
  const itemProps = omit(props, [
    'icon',
    'id',
    'minWidth',
    'route',
    'onClick',
    'label',
    'isActiveIcon',
    'isActive',
    'isDisabled'
  ]);
  const { className, isActive, route, icon, isActiveIcon, label, isDisabled, onClick } = props;
  const goto = useGoto();

  const handleItemClick = () => {
    if (route) {
      goto(route);

      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Menu.Item
      {...itemProps}
      className={clsx([className, isActive && 'active'])}
      onClick={route || onClick ? handleItemClick : undefined}
      disabled={isDisabled}
      icon={
        icon && (
          <FontAwesomeIcon
            icon={icon}
            className={clsx('item-icon', isActiveIcon === true && 'active-icon')}
          />
        )
      }
    >
      {label}
    </Menu.Item>
  );
};

export default DropdownMenuItem;
