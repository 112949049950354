import React from 'react';

import { Logo, getRoute, useLanguage } from '@dam/shared';

// import LanguageNavigation from 'components/navigations/languageNavigation';
import UserNavigation from 'components/navigations/userNavigation';
import { routes } from 'services/router/routes';

import logo from '../../../assets/images/logo.svg';

import * as S from './HeaderSc';

const Header: React.FC = () => {
  const language = useLanguage();

  return (
    <S.Container>
      <S.LogoContainer
        to={getRoute(routes.investors.users._path, {
          query: { language }
        })}
      >
        <Logo logoImg={logo} className="logo" />
      </S.LogoContainer>
      <div className="right">
        {/* <LanguageNavigation /> */}
        <UserNavigation />
      </div>
    </S.Container>
  );
};

export default Header;
