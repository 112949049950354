import React from 'react';

import { Navigate } from 'react-router';

const HealthPage = React.lazy(() => import('components/pages/healthPage'));
const UserSinglePage = React.lazy(() => import('components/pages/userSinglePage'));
const UsersPage = React.lazy(() => import('components/pages/usersPage'));

export const pathParams = {
  language: ':language',
  listParams: ':listParams',
  id: ':id',
  view: ':view'
};

const basePath = `${pathParams.language}`;

const getListPath = (base: string, element: React.ReactNode) => ({
  list: {
    _segment: `list/${pathParams.listParams}`,
    _path: `/${basePath}/${base}/list/${pathParams.listParams}`,
    _element: <React.Suspense fallback={<div />}>{element}</React.Suspense>
  }
});

const getSinglePath = (base: string, element: React.ReactNode, hasView?: boolean) => {
  const segment = hasView ? `single/${pathParams.id}/:view` : `single/${pathParams.id}`;

  return {
    single: {
      _segment: segment,
      _path: `/${basePath}/${base}/${segment}`,
      _element: <React.Suspense fallback={<div />}>{element}</React.Suspense>
    }
  };
};

export const routes = {
  /**
   * HEALTH
   */
  health: {
    _segment: 'health',
    _path: `/${basePath}/health`,
    _element: (
      <React.Suspense fallback={<div />}>
        <HealthPage />
      </React.Suspense>
    )
  },

  /**
   * INVESTORS
   */
  investors: {
    _segment: 'investors',
    _path: `/${basePath}/investors`,
    _index: <Navigate to="users" replace />,

    users: {
      _segment: 'users',
      _path: `/${basePath}/investors/users`,
      _index: (
        <React.Suspense fallback={<div />}>
          <UsersPage />
        </React.Suspense>
      ),

      ...getListPath('investors/users', <UsersPage />),
      ...getSinglePath('investors/users', <UserSinglePage />)
    }
  }
};
