/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router';

import { getRoute, useLanguage } from '@dam/shared';

import { routes } from 'services/router/routes';

const HealthPage: React.FC = () => {
  const { keycloak } = useKeycloak();
  const language = useLanguage();

  if (keycloak?.authenticated) {
    return <Navigate to={getRoute(routes.investors._path, { query: { language } })} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <span>Status ok</span>
    </div>
  );
};

export default HealthPage;
