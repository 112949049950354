// import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { i18n } from '@lingui/core';
// import { t } from '@lingui/macro';

import type { GetNavigationConfig } from 'services/navigations/navigationConfig.types';

// import { AuthRole } from '../auth/auth.types';
// import { routes } from '../router/routes';

// const { user } = routes;

const getUserNavigation: GetNavigationConfig = () => [
  //   {
  //     id: 'profile',
  //     label: i18n._(t`Profile`),
  //     icon: regular('user'),
  //     //   route: user.profile._path,
  //     auth: [AuthRole.USER, AuthRole.ADMIN]
  //   }
];

export default getUserNavigation;
