/**
 * Do not edit this file. its generically produced form js modules
 */
import type { LanguageConfig } from '@dam/types';

export const languageConfig: LanguageConfig = {
  locales: ['de', 'fr', 'it', 'en'],
  defaultLocale: 'en',
  localeDetection: false
};
