import type { BreadcrumbConfig, NavigationConfig } from '@arpia/react-navigation';

import getMainNavigationConfig from './getMainNavigation';

type Config = {
  pathname: string;
  language: string;
  append?: BreadcrumbConfig;
};

const getSlug = (route?: string) => {
  if (!route) {
    return '';
  }

  const parts = route.split('/');

  if (parts.length === 0) {
    return '';
  }

  return parts[parts.length - 1];
};

const walkNav = (
  navItem: NavigationConfig,
  parts: string[],
  level: number,
  anchestors: BreadcrumbConfig[],
  breadcrumbs: BreadcrumbConfig[]
): BreadcrumbConfig[] => {
  if (getSlug(navItem.route) === parts[level]) {
    const brObj: BreadcrumbConfig = {
      breadcrumbName: navItem.title || '',
      path: navItem.route || '',
      children: anchestors.length === 0 ? undefined : anchestors
      //    noNav: navItem.noNav
    };

    let match: NavigationConfig | undefined;
    const anch: BreadcrumbConfig[] = [];

    navItem.children?.forEach(child => {
      if (getSlug(child.route) === parts[level + 1]) {
        match = child;
      } else if (!child.isDisabled) {
        anch.push({
          breadcrumbName: child.title || '',
          path: child.route || ''
        });
      }
    });

    if (match) {
      walkNav(match, parts, level + 1, anch, breadcrumbs);
    }

    if (level !== 0) {
      breadcrumbs.unshift(brObj);
    }
  }

  return breadcrumbs;
};

const getBreadcrumbsConfig = (params: Config): BreadcrumbConfig[] | null => {
  const parts = params.pathname.replace('/', '').split('/');

  let navConfig: NavigationConfig | undefined;

  if (parts[0] !== 'user') {
    navConfig = getMainNavigationConfig({ language: params.language });
  }

  if (!navConfig || !navConfig.children || !navConfig.children.length) {
    return null;
  }

  const breadcrumbs = walkNav(navConfig, parts, 0, [], []);
  if (params.append) {
    breadcrumbs.push(params.append);
  }

  return breadcrumbs;
};

export default getBreadcrumbsConfig;
