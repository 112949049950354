/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import type { PopoverProps } from 'antd';
import { Menu, Popover } from 'antd';
import clsx from 'clsx';
import { useImmer } from 'use-immer';

import { useContainer } from '@dam/shared';

import type { NavigationItem } from 'services/navigations/navigationConfig.types';
import { useAppStore } from 'services/store/app';

import DropdownMenuItem from './DropdownMenuItem';
import * as S from './DropdownMenuSc';

type DropdownMenuProps = {
  items?: NavigationItem[];
  isActiveIcon?: boolean;
  minWidth?: number;
  className?: string;
  activeId?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  popover?: Omit<PopoverProps, 'content'> & { children: React.ReactElement };
};

type DropdownMenuState = {
  isOpen: boolean;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  minWidth,
  className,
  activeId,
  children,
  isActiveIcon,
  popover,
  onClick
}) => {
  const { content } = useContainer();
  const isDialogOpen = useAppStore(store => store.general.isDialogOpen);
  const [state, setState] = useImmer<DropdownMenuState>({
    isOpen: false
  });

  const handleVisibleChange = (isVisible: boolean) => {
    if (popover?.onVisibleChange) {
      popover.onVisibleChange(isVisible);
    }

    setState(draft => {
      draft.isOpen = isVisible;
    });
  };

  useEffect(() => {
    if (!popover?.visible === undefined) {
      return;
    }

    setState(draft => {
      draft.isOpen = popover?.visible || false;
    });
  }, [popover?.visible, setState]);

  useEffect(() => {
    setState(draft => {
      draft.isOpen = false;
    });
  }, [isDialogOpen, setState]);

  useEffect(
    () => () => {
      setState(draft => {
        draft.isOpen = false;
      });
    },
    [isDialogOpen, setState]
  );

  const menu = (
    <S.StyledMenu $minWidth={minWidth}>
      <Menu
        className={clsx(['dropdown-menu', className])}
        activeKey={activeId || ''}
        selectedKeys={[]}
        onClick={onClick}
        getPopupContainer={() => content}
      >
        {items?.map(item => (
          <DropdownMenuItem
            key={item.id}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...item}
            isActiveIcon={isActiveIcon}
            isActive={item.id === activeId}
          />
        ))}

        {children}
      </Menu>
    </S.StyledMenu>
  );

  if (!popover) {
    return menu;
  }

  return (
    <Popover
      trigger="click"
      align={{ offset: [0, -14] }}
      getPopupContainer={() => content}
      placement="bottomLeft"
      {...popover}
      visible={state.isOpen}
      onVisibleChange={handleVisibleChange}
      content={menu}
    >
      {popover.children}
    </Popover>
  );
};

export default DropdownMenu;
