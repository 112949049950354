import React from 'react';

import type { FallbackProps } from 'react-error-boundary';

type PageContentErrorFallbackProps = FallbackProps & {
  children?: React.ReactNode;
};

const PageContentErrorFallback: React.FC<PageContentErrorFallbackProps> = ({
  error,
  resetErrorBoundary
}) => (
  <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
    <button type="button" onClick={resetErrorBoundary}>
      Try again
    </button>
  </div>
);

export default PageContentErrorFallback;
