/* eslint-disable arrow-body-style */
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { SingleContentConfig } from 'types';

import * as S from './TabNavigationSc';

type Content = Omit<SingleContentConfig, 'content'> & {
  content?: React.ReactNode;
};

export type TabNavigationProps = {
  className?: string;
  contents: Content[];
  currentView: string;
  onViewChange: (view: string) => void;
};
// eslint-disable-next-line arrow-body-style
const TabNavigation: React.FC<TabNavigationProps> = ({
  className,
  contents,
  currentView,
  onViewChange
}) => {
  const renderItems = () => {
    return contents.map(content => {
      if (!content.isHidden) {
        return {
          key: content.key,
          disabled: content.isDisabled,
          label: (
            <>
              {content.navIcon && <FontAwesomeIcon icon={content.navIcon} />}
              {content.navLabel}
            </>
          )
        };
      }

      return undefined;
    });
  };

  return (
    <S.StyledNavigation
      className={className}
      defaultActiveKey="1"
      onChange={onViewChange}
      activeKey={currentView}
      items={renderItems() as any}
    />
  );
};

export default TabNavigation;
