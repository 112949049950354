import React, { useEffect } from 'react';

import type { BreadcrumbConfig} from '@arpia/react-navigation';
import { BreadcrumbNavigation } from '@arpia/react-navigation';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router';
import { useImmer } from 'use-immer';

import { useContainer, useLanguage } from '@dam/shared';

import { useUserData } from 'hooks';
import getBreadcrumbsConfig from 'services/navigations/getBreadcrumbs';

import * as S from './BreadcrumbsSc';

type BreadcrumbsState = {
  config: BreadcrumbConfig[] | null;
};

const Breadcrumbs: React.FC = () => {
  const [state, setState] = useImmer<BreadcrumbsState>({
    config: null
  });
  const { pathname } = useLocation();
  const { content } = useContainer();
  const language = useLanguage();

  const userData = useUserData();

  useEffect(() => {
    let append: BreadcrumbConfig | undefined;

    if (userData.isUserSingle) {
      append = {
        path: pathname,
        breadcrumbName: `${userData.query.data?.firstName} ${userData.query.data?.lastName}`
      };
    }
    const config = getBreadcrumbsConfig({ pathname, language, append });

    setState(draft => {
      draft.config = config;
    });
  }, [setState, pathname, language, userData.isUserSingle, userData.query.data]);

  return (
    <S.Container>
      {state.config && (
        <BreadcrumbNavigation
          separator={<FontAwesomeIcon icon={regular('arrow-right')} />}
          config={state.config}
          getPopupContainer={() => content}
        />
      )}
    </S.Container>
  );
};

export default Breadcrumbs;
