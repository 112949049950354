/* eslint-disable @typescript-eslint/indent */

import type { TableSearchQueryParams } from '@arpia/react-table/dist/src/types';
import { useMutation, useQuery } from 'react-query';

import type { UseMutation, UseQueryWithParams } from '../../api.types';

import {
  api_damDeleteKycFailedState,
  api_damGetUserByEmail,
  api_damPostUsers,
  api_damOverwriteUserStatus,
  api_damPutResetPassword,
  api_damUpdateInvestorKeycloackId,
  api_damGetAllUsers,
  api_damUpdateInvestor
} from './damUser.queries';
import type {
  DamGetInvestorsPayload,
  DamGetUserByEmail,
  DamResetPasswordPayload,
  DamUpdateKeycloakIdPayload,
  DamUpdateKeycloakIdResponse,
  DamUpdateUserPayload,
  DamUser,
  DamUserRevertKycFailedPayload,
  DamUsersResponse,
  DamUserStatusPayload,
  DamUserStatusResponse
} from './damUser.types';

/**
 * QUERY (useQuery)
 */
export const useDamGetUsers: UseQueryWithParams<DamGetInvestorsPayload, DamUsersResponse> = (
  params,
  options
) => useQuery(['getUsers', params], () => api_damGetAllUsers(params), options as any);

export const useDamPostUsers: UseQueryWithParams<TableSearchQueryParams, DamUsersResponse> = (
  params,
  options
) => useQuery(['users', params], () => api_damPostUsers(params), options as any);

export const useDamUserByEmail: UseQueryWithParams<DamGetUserByEmail, DamUser> = (
  params,
  options
) => useQuery(['singleUser', params], () => api_damGetUserByEmail(params), options as any);

/**
 * MUTATIONS (useMutation)
 */
export const useDamUpdateInvestorKeycloakId: UseMutation<
  DamUpdateKeycloakIdPayload,
  DamUpdateKeycloakIdResponse
> = options => useMutation(payload => api_damUpdateInvestorKeycloackId(payload), options);

export const useDamOverwriteUserStatus: UseMutation<
  DamUserStatusPayload,
  DamUserStatusResponse
> = options => useMutation(payload => api_damOverwriteUserStatus(payload), options);

export const useDamResetPassword: UseMutation<DamResetPasswordPayload, any> = options =>
  useMutation(payload => api_damPutResetPassword(payload), options);

export const useDamRevertFailedKycState: UseMutation<
  DamUserRevertKycFailedPayload,
  DamUserStatusResponse
> = options => useMutation(payload => api_damDeleteKycFailedState(payload), options);

export const useDamUpdateInvestor: UseMutation<DamUpdateUserPayload, DamUser> = options =>
  useMutation(payload => api_damUpdateInvestor(payload), options);
