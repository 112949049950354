import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type {
  AppState,
  SetDialogOpenPayload,
  SetLanguagePayload,
  SetMobileNavOpenPayload,
  SetSidebarCollapsedPayload,
  SetUnauthorizedRoutePayload
} from './appStore.types';

const devToolsName = 'APP_STORE';

export const useAppStore = create<AppState>()(
  devtools(
    immer(set => ({
      general: {
        language: 'en',
        isDialogOpen: false,
        isSidebarCollapsed: false,
        isUnauthorizedRoute: false,
        isMobileNavOpen: false
      },
      setAppLanguage: (payload: SetLanguagePayload) =>
        set(draft => {
          draft.general.language = payload.language;
        }),
      setDialog: (payload: SetDialogOpenPayload) =>
        set(draft => {
          draft.general.isDialogOpen = payload.isOpen;
        }),
      setSidebarCollapsed: (payload: SetSidebarCollapsedPayload) =>
        set(draft => {
          draft.general.isSidebarCollapsed = payload.isCollapsed;
        }),
      setUnauthorizedRoute: (payload: SetUnauthorizedRoutePayload) =>
        set(draft => {
          draft.general.isUnauthorizedRoute = payload.isUnauthorized;
        }),
      setMobileNavOpen: (payload: SetMobileNavOpenPayload) =>
        set(draft => {
          draft.general.isMobileNavOpen = payload.open;
        }),

      resetLayout: () =>
        set(draft => {
          draft.general.isDialogOpen = false;
          draft.general.isSidebarCollapsed = false;
        })
    })),
    { name: devToolsName }
  )
);
