/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router';

import { Loader } from '@dam/shared';
import type { Locale } from '@dam/types';

import { languageConfig } from 'services/i18n/language.config';
import { useAuthStore } from 'services/store/auth';

type AuthProviderProps = {
  children: React.ReactNode;
};

type Timeout = ReturnType<typeof setTimeout>;

const AppAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();
  const isLoggingOut = useAuthStore(st => st.isLoggingOut);
  const setIsLoggingOut = useAuthStore(st => st.setIsLoggingOut);
  const [isShowChildren, setShowChildren] = useState(false);
  const tm1 = useRef<Timeout>();

  const language = useMemo(() => {
    const part = pathname.replace('/', '').split('/')[0];

    if (languageConfig.locales.includes(part as Locale)) {
      return part;
    }

    return languageConfig.defaultLocale;
  }, [pathname]);

  useEffect(() => {
    if (keycloak?.token) {
      const decoded = jwt_decode(keycloak?.token) as any;
      if (!decoded.groups.includes('ADMIN')) {
        setIsLoggingOut('true');
        keycloak.logout();
      }
    }
  }, [keycloak?.token, setIsLoggingOut, keycloak]);

  useEffect(() => {
    if (initialized) {
      tm1.current = setTimeout(() => {
        setShowChildren(true);
      }, 10);
    }
  }, [setShowChildren, initialized]);

  useEffect(
    () => () => {
      if (tm1.current) {
        clearTimeout(tm1.current);
      }
    },
    []
  );

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }

    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  useEffect(() => {
    if (keycloak?.authenticated || !initialized) {
      return;
    }

    if (initialized) {
      if (!keycloak?.authenticated) {
        keycloak.login();
      }
    }
  }, [keycloak, initialized, language]);

  useEffect(() => {
    if (keycloak?.authenticated || !initialized) {
      return;
    }

    if (initialized) {
      if (!keycloak?.authenticated) {
        keycloak.login();
        setIsLoggingOut('true');
      }
    }
  }, [keycloak, initialized, language, setIsLoggingOut]);

  if (!isShowChildren || !initialized || !keycloak || isLoggingOut === 'true') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
          justifyContent: 'center'
        }}
      >
        <Loader size={32} />
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export default AppAuthProvider;
