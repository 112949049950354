import React from 'react';

import { Trans } from '@lingui/macro';

import { Exception } from '@dam/shared';

import PageContent from 'components/ui/pageContent';
import { routes } from 'services/router/routes';

const NotFoundPage: React.FC = () => (
  <PageContent title={<Trans>Page not found</Trans>}>
    <Exception
      routes={routes}
      code={404}
      message={<Trans>The requested page was not found</Trans>}
    />
  </PageContent>
);
export default NotFoundPage;
