import type { Locale } from '@lingui/core';
import moment from 'moment';

import { useLanguage } from '@dam/shared';

type Params = {
  date?: string;
  isDateTime?: boolean;
  isUtc?: boolean;
};
type FormatDateHandler = (params: Params) => string | undefined;

type UseFormatDate = () => FormatDateHandler;

const dateFormats: {
  [key in Locale]: { date: string; dateTime: string };
} = {
  en: {
    date: 'DD MMM YYYY',
    dateTime: 'DD MMM YYYY, hh:mm a'
  },
  de: {
    date: 'DD.MM.YYYY',
    dateTime: 'DD.MM.YYYY, hh:mm a'
  }
};

export const useFormatDate: UseFormatDate = () => {
  const language = useLanguage();

  const handleFormatDate: FormatDateHandler = ({ date, isDateTime, isUtc }) => {
    if (!date) {
      return undefined;
    }

    if (!dateFormats[language]) {
      return undefined;
    }

    const dateObj = moment(date);

    if (!dateObj.isValid()) {
      return undefined;
    }

    if (isUtc) {
      dateObj.utc();
    }

    if (!isDateTime) {
      return dateObj.format(dateFormats[language].date);
    }

    return dateObj.format(dateFormats[language].dateTime);
  };

  return handleFormatDate;
};
