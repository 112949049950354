import { Link } from 'react-router-dom';
import tw, { styled, css } from 'twin.macro';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div(() => [
  css`
    ${tw`w-full h-full flex flex-row px-12`}

    & .right {
      ${tw`h-full w-full flex flex-row items-center justify-end`}
    }
  `
]);

export const LogoContainer = styled(Link)(() => [
  css`
    ${tw`flex h-32 items-center bg-white mt-10`}

    .logo {
      ${tw`h-auto`}

      img {
        ${tw`w-160 h-50 block`}
      }
    }
  `
]);
