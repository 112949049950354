// eslint-disable-next-line no-unused-vars
import { MenuProps } from 'antd';
import tw, { styled, css } from 'twin.macro';

type MainNavContainerProps = { collapsedWidth?: number };

// eslint-disable-next-line import/prefer-default-export
export const MainNavContainer = styled.div<MainNavContainerProps>(({ collapsedWidth }) => [
  css`
    & .ant-menu {
      /*  &.no-transition * {
        transition: none !important;
      }
      */

      & .ant-menu-item {
        ${tw`font-600 text-neutral-900 bg-neutral-100 text-15`}

        &:after {
          ${tw`border-none`}
        }

        & .ant-menu-title-content {
          ${tw`text-neutral-900`}

          & a {
            ${tw`text-neutral-900`}
          }
        }

        &.ant-menu-item-selected {
          ${tw`bg-primary-bg text-white`}

          & .ant-menu-title-content {
            ${tw`text-primary`}

            & a {
              ${tw`text-white`}
            }
          }
        }
      }

      & .ant-menu-submenu-title {
        ${tw`font-600 text-neutral-900 bg-neutral-100`}

        & svg {
          ${tw`text-neutral-900`}
        }
      }

      /* ${tw`bg-neutral-100 font-700 text-14`}

      &.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
        ${tw`bg-neutral-100 font-700 text-14`}
      }

      &.ant-menu-inline-collapsed {
        width: ${typeof collapsedWidth === 'number' ? `${collapsedWidth}px` : 'auto'} !important;
      }

      &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
      &.ant-menu-inline-collapsed > .ant-menu-item {
        padding: 0 ${typeof collapsedWidth === 'number' ? `${collapsedWidth - 38}px` : 'auto'};
        margin: 0;
      }

      &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
      &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
        ${tw`bg-neutral-100`}
        &:after {
          border: none;
        }
      }

      & .ant-menu-submenu > .ant-menu {
        ${tw`bg-neutral-100`}
      }

      &.ant-menu-inline .ant-menu-item:not(:last-child),
      &.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        margin: 0;
      }

      &.ant-menu > .ant-menu-item-divider {
        ${tw`bg-gray-500`}
      }

      &.ant-menu-inline .ant-menu-item {
        ${tw`m-0`}
        height: 38px;
        line-height: 38px;

        &.doc-item {
          ${tw`font-600`}
          height: 28px;
          line-height: 28px;

          & svg {
            ${tw`mr-6 text-gray-500 text-14`}
          }
        }
      }

      & a {
        color: inherit;
      }

      & i.icon-before {
        ${tw`mr-6`}
      }

      & .ant-menu-submenu-expand-icon {
        ${tw`hover:text-primary transition-all duration-150 w-26 h-26 p-6`}
        right: 8px;

        transform-origin: center;
      }

      & .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
        transform: rotate(180deg) translateY(13px);
      }

      &.docs .ant-menu-item:not(#\/docs) {
        height: 3.2rem !important;
        line-height: 3.2rem !important;
      } */
    }
  `
]);
