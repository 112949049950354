/*   success: ['fas', 'check-circle'],
  error: ['fas', 'exclamation-circle'],
  warning: ['fas', 'exclamation-triangle'],
  info: ['fas', 'info-circle']
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationCircle,
  faLeftFromLine,
  faUserFriends,
  faHome,
  faUserShield,
  faUser,
  faChartLineUp,
  faTerminal,
  faWindowMaximize,
  faWindowMinimize,
  faInfoCircle,
  faAddressCard,
  faPen,
  faCog,
  faMoneyCheckDollarPen,
  faCopy,
  faSave,
  faExclamationTriangle,
  faCheckCircle,
  faUndo,
  faRightLeft,
  faTimer,
  faScaleBalanced,
  faFileUser,
  faUserClock,
  faSearch
} from '@fortawesome/pro-solid-svg-icons';

const addIconsFas = (): void => {
  library.add(
    faExclamationCircle,
    faLeftFromLine,
    faUserFriends,
    faHome,
    faUserShield,
    faUser,
    faChartLineUp,
    faTerminal,
    faWindowMaximize,
    faWindowMinimize,
    faInfoCircle,
    faAddressCard,
    faPen,
    faCog,
    faMoneyCheckDollarPen,
    faCopy,
    faSave,
    faExclamationTriangle,
    faCheckCircle,
    faUndo,
    faRightLeft,
    faTimer,
    faScaleBalanced,
    faFileUser,
    faUserClock,
    faSearch
  );
};

export default addIconsFas;
