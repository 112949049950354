/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect } from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

import { ERROR_CODES } from 'consts';
import { useApiErrorStore } from 'services/store/apiError';

export type UserContextState = {
  errorMessage: string;
};

export type ErrorHandleProviderState = {
  errorMessage: string;
};

export const ErrorHandleContext = createContext({} as UserContextState);

type ErrorHandleProviderProps = {
  children: React.ReactNode;
};

const ErrorHandleProvider: React.FC<ErrorHandleProviderProps> = ({ children }) => {
  const [state, setState] = useImmer<ErrorHandleProviderState>({
    errorMessage: ''
  });

  useEffect(() => {
    useApiErrorStore.subscribe(setState, (state: any) => state.errorMessage);

    if (state.errorMessage === ERROR_CODES.NON_AUTH_ERROR) {
      toast(i18n._(t`Token expired. Data may be outdated. Please reload the page.`), {
        type: 'info'
      });
    }
    if (state.errorMessage === ERROR_CODES.SERVER_ERROR) {
      toast(i18n._(t`There has been some server issue. Please try again.`), {
        type: 'error'
      });
    }
  }, [state.errorMessage, setState]);

  return (
    <ErrorHandleContext.Provider value={{ errorMessage: state.errorMessage }}>
      {children}
    </ErrorHandleContext.Provider>
  );
};

export default ErrorHandleProvider;
