/* eslint-disable global-require */
import { i18n } from '@lingui/core';
import countries from 'i18n-iso-countries';
import deCountries from 'i18n-iso-countries/langs/de.json';
import enCountries from 'i18n-iso-countries/langs/en.json';

import { messages as messagesDe } from 'services/i18n/locales/de/messages';
import { messages as messagesEn } from 'services/i18n/locales/en/messages';

const intiI18n = () => {
  countries.registerLocale(enCountries);
  countries.registerLocale(deCountries);

  i18n.load('en', messagesDe);
  i18n.load('de', messagesEn);

  i18n.loadLocaleData('en', { plurals: () => {} });
  i18n.loadLocaleData('de', { plurals: () => {} });

  i18n.activate('en');
};

export default intiI18n;
