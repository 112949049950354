/* eslint-disable max-len */
import create from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { AuthState } from './authStore.types';

const devToolsName = 'AUTH_STORE';
const storeKey = 'storylines-admin-auth';

export const useAuthStore = create<AuthState>()(
  devtools(
    subscribeWithSelector(
      persist(
        immer(set => ({
          isLoggingOut: 'false',
          setIsLoggingOut: val => {
            set(draft => {
              draft.isLoggingOut = val;
            });
          },

          resetStore: () => {
            set(draft => {
              draft.isLoggingOut = 'false';
            });
          }
        })),
        {
          name: storeKey
        }
      )
    ),
    { name: devToolsName }
  )
);
