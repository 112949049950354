import React from 'react';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Button, Layout } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import { Outlet } from 'react-router';

import { Head } from '@dam/shared';

import Breadcrumbs from 'components/navigations/breadcrumbs';
import MainNavigation from 'components/navigations/mainNavigation';
import { useAppStore } from 'services/store/app';

import favicon from '../../../assets/images/favicon.webp';
import Header from '../header';

import * as S from './PageSc';

const Page: React.FC = () => {
  const setSidebarCollapsed = useAppStore(state => state.setSidebarCollapsed);
  const isSbCollapsed = useAppStore(state => state.general.isSidebarCollapsed);

  const handleToggleSidebar = () => {
    setSidebarCollapsed({ isCollapsed: !isSbCollapsed });
  };

  return (
    <>
      <Head
        description={i18n._(t`Storylines Management Cockpit`)}
        keywords={i18n._(t`digital assets, asset tokenization, storylines`)}
        favicon={<link rel="icon" type="image/webp" sizes="32x32" href={favicon} />}
        title={i18n._(t`Storylines - Management Cockpit`)}
      />
      <S.Container>
        <S.HeaderContainer>
          <Header />
        </S.HeaderContainer>
        <Layout>
          <S.SidebarContainer
            collapsible
            width={200}
            collapsedWidth={60}
            collapsed={isSbCollapsed}
            trigger={
              <Button type="link" title={i18n._(t`Collapse sidebar`)} onClick={handleToggleSidebar}>
                <FontAwesomeIcon icon={solid('left-from-line')} />
              </Button>
            }
          >
            <Scrollbars className="scroll sidebar" autoHide>
              <MainNavigation />
            </Scrollbars>
          </S.SidebarContainer>
          <S.ContentContainer>
            <Breadcrumbs />
            <Outlet />
          </S.ContentContainer>
        </Layout>
      </S.Container>
    </>
  );
};
export default Page;
