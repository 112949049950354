/**
 * REACT QUERY
 *
 * all available options see:
 * https://react-query.tanstack.com/reference/QueryClient
 *
 */

import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /* staleTime: Infinity */
      retry: false
    }
  }
});

export default queryClient;
