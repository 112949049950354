import type { MediaQueries } from '@dam/types';

import { appStyles } from './appStyles';

export const mediaQueries: MediaQueries = {
  desktop: `(min-width: ${appStyles.breakpoints.md}px)`,
  tablet: `(min-width: ${appStyles.breakpoints.xs}px) and (max-width: ${
    appStyles.breakpoints.md - 1
  }px)`,
  phone: `(max-width: ${appStyles.breakpoints.xs - 1}px)`,
  mobile: `(max-width: ${appStyles.breakpoints.md - 1}px)`
};

export default mediaQueries;
