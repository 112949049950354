import type { NavigationConfig } from '@arpia/react-navigation';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { getRoute } from '@dam/shared';

import { routes } from '../router/routes';

type Config = {
  language: string;
  activeOnRouteMatch?: string;
};

const getMainNavigationConfig = (params: Config): NavigationConfig => ({
  id: 'main-navigation',
  route: `/${params.language}`,
  children: [
    {
      id: 'investors',
      title: i18n._(t`Investors`),
      icon: solid('user-friends'),

      route: getRoute(routes.investors._path, {
        query: { language: params.language }
      }),
      noNav: true,
      isActiveIfChildActive: true,
      children: [
        {
          id: 'users',
          title: i18n._(t`Users`),
          icon: solid('user'),
          route: getRoute(routes.investors.users._path, {
            query: { language: params.language }
          }),
          activeOnRouteMatch: params.activeOnRouteMatch
        }
      ]
    }
  ]
});

export default getMainNavigationConfig;
