/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';

import { Navigation, useNavigationActions } from '@arpia/react-navigation';
import { useLocation } from 'react-router';

import { useContainer, useLanguage } from '@dam/shared';

import getMainNavigationConfig from 'services/navigations/getMainNavigation';
import { useAppStore } from 'services/store/app';

import * as S from './MainNavigationSc';

const NAVIGATION_ID = 'main';

const MainNavigation: React.FC = () => {
  const language = useLanguage();
  const { setNavigationConfig, setOpenOnLoadConfig } = useNavigationActions();
  const { current: setNavigationConfigRef } = useRef(setNavigationConfig);
  const { content } = useContainer();
  const { pathname } = useLocation();
  const isSbCollapsed = useAppStore(state => state.general.isSidebarCollapsed);

  useEffect(() => {
    if (isSbCollapsed) {
      setOpenOnLoadConfig(NAVIGATION_ID, undefined);
    }
    const config = getMainNavigationConfig({
      language,
      activeOnRouteMatch: pathname
    });
    setNavigationConfig(NAVIGATION_ID, config);
  }, [
    setNavigationConfigRef,
    language,
    pathname,
    isSbCollapsed,
    setNavigationConfig,
    setOpenOnLoadConfig
  ]);

  return (
    <S.MainNavContainer>
      <Navigation
        navigationId={NAVIGATION_ID}
        config={getMainNavigationConfig({ language })}
        getPopupContainer={() => content}
        mode="inline"
        inlineCollapsed={isSbCollapsed}
        openOnLoad={isSbCollapsed ? undefined : { levels: [0, 1, 2] }}
      />
    </S.MainNavContainer>
  );
};

export default MainNavigation;
