/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';


import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router';

import { Loader } from '@dam/shared';
import type { ExceptionProps } from '@dam/shared';

import TabNavigation from '../tabNavigation';
import type { TabNavigationProps } from '../tabNavigation/TabNavigation';

import PageContentErrorFallback from './PageContentErrorFallback';
import * as S from './PageContentSc';

type PageProps = {
  title: string | React.ReactNode;
  titleIcon?: React.ReactNode;
  tittleBottom?: React.ReactNode;
  titleRight?: React.ReactNode;
  children?: React.ReactNode;
  isScrollable?: boolean;
  isLoading?: boolean;
  exception?: ExceptionProps;
  tabNavigation?: TabNavigationProps;
};

const PageContent: React.FC<PageProps> = ({
  children,
  isScrollable,
  title,
  titleIcon,
  isLoading,
  tabNavigation,
  titleRight
}) => {
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      FallbackComponent={PageContentErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader size={54} />}>
        <>
          <S.ContentHeader>
            <div className="inner">
              <div className="top">
                <S.BackButton
                  type="link"
                  icon={<FontAwesomeIcon icon={regular('chevron-left')} />}
                  onClick={() => navigate(-1)}
                />
                {titleIcon}
                <h1>{title}</h1>
                {titleRight && <div className="right">{titleRight}</div>}
              </div>
              {tabNavigation && (
                <div className="bottom">
                  <TabNavigation {...tabNavigation} />
                </div>
              )}
            </div>
          </S.ContentHeader>
          <S.ContentMain className={clsx([isScrollable && !isLoading ? 'scroll' : 'no-scroll'])}>
            <div className="inner">
              {isLoading ? <Loader size={54} boxHeight="100%" flex /> : children}
            </div>
          </S.ContentMain>
        </>
      </React.Suspense>
    </ErrorBoundary>
  );
};
export default PageContent;
