/* eslint-disable import/prefer-default-export */
import { Button, Layout } from 'antd';
import tw, { css, styled } from 'twin.macro';

const { Header, Content } = Layout;

export const ContentContainer = styled(Layout)(() => [
  css`
    ${tw`bg-neutral-0`};
  `
]);

export const ContentHeader = styled(Header)(() => [
  css`
    &.ant-layout-header {
      ${tw`bg-neutral-0 px-24 py-0 h-auto`};
      flex: unset;
      line-height: unset;

      & .inner {
        & .top {
          ${tw`flex flex-row items-center`};

          & h1 {
            ${tw`font-700 text-20 lg:text-26 m-0 p-0 `};
            line-height: 1.1;

            & b {
              ${tw` text-primary font-700`};
            }
          }

          & .title-icon {
            ${tw`text-18 mr-8`};
          }

          & .right {
            ${tw`ml-auto h-full flex flex-row items-center`};

            & .ant-btn {
              ${tw`ml-12`};
            }

            & .ant-btn:not(.reload-btn) {
              ${tw`min-w-120 `};
            }
          }
        }

        & .bottom {
          ${tw`pt-6`};
        }
      }
    }
  `
]);

export const ContentMain = styled(Content)(() => [
  css`
    ${tw`bg-neutral-0 relative`};

    & .inner {
      ${tw`absolute top-0 left-0 right-0 bottom-0`};
    }

    &.scroll {
      ${tw`overflow-y-auto h-full `};

      & .inner {
        ${tw`p-24`};
      }
    }

    &.no-scroll {
      ${tw`overflow-hidden h-full `};

      & .inner {
        ${tw`h-full p-0`};
      }
    }
  `
]);

export const BackButton = styled(Button)(() => [
  css`
    ${tw`bg-transparent p-0 text-neutral-400 hover:text-primary w-16 mr-6`};
  `
]);
