/* eslint-disable consistent-return */
import React, { useEffect } from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Trans } from '@lingui/macro';
import { useKeycloak } from '@react-keycloak/web';
import { Popover } from 'antd';
import clsx from 'clsx';
import jwt_decode from 'jwt-decode';
import { matchRoutes, useLocation } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { HeaderButton, useContainer } from '@dam/shared';

import DropdownMenu from 'components/ui/dropdownMenu';
import getUserNavigation from 'services/navigations/getUserNavigation';
import type { NavigationItem } from 'services/navigations/navigationConfig.types';

type UserNavigationState = {
  isNavOpen: boolean;
};

const UserNavigation: React.FC = () => {
  const { keycloak } = useKeycloak();
  const [state, setState] = useImmer<UserNavigationState>({
    isNavOpen: false
  });

  const { content } = useContainer();
  const config = getUserNavigation();
  const { pathname } = useLocation();
  const match = matchRoutes(
    config.map(item => ({ path: `${item.route}/*`, end: false })),
    pathname || ''
  );
  const activeItem = match?.[0]?.pathnameBase.split('/')[3];

  const handleLogout = () => {
    if (!keycloak) {
      return;
    }

    keycloak.logout();
  };

  const navConfig: NavigationItem[] = [
    ...config,
    {
      id: 'sign-out',
      label: <Trans>Logout</Trans>,
      icon: regular('sign-out'),
      onClick: handleLogout
    }
  ];

  const parseEmailFromToken = () => {
    if (keycloak?.token) {
      const decoded = jwt_decode(keycloak?.token) as any;

      return decoded?.email;
    }
  };

  useEffect(() => {
    setState(draft => {
      draft.isNavOpen = false;
    });
  }, [pathname, setState]);

  //   if (!keycloak?.token) {
  //     return null;
  //   }

  return (
    <Popover
      visible={state.isNavOpen}
      onVisibleChange={isVisible =>
        setState(draft => {
          draft.isNavOpen = isVisible;
        })
      }
      getPopupContainer={() => content}
      placement="bottomRight"
      align={{ offset: [0, -14] }}
      content={<DropdownMenu items={navConfig} activeId={activeItem} minWidth={200} />}
    >
      <HeaderButton
        className={clsx([state.isNavOpen && 'active'])}
        icon={regular('user')}
        isActive={state.isNavOpen || match !== null}
      >
        {parseEmailFromToken()}
      </HeaderButton>
    </Popover>
  );
};
export default UserNavigation;
