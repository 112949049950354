import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faGlobe,
  faCheck,
  faUser,
  faSignOut,
  faArrowRight,
  faCog,
  faUndo,
  faSync,
  faGripLines,
  faTimes,
  faEnvelope
} from '@fortawesome/pro-regular-svg-icons';

const addIconsFar = (): void => {
  library.add(
    faChevronLeft,
    faGlobe,
    faCheck,
    faUser,
    faSignOut,
    faArrowRight,
    faCog,
    faUndo,
    faSync,
    faGripLines,
    faTimes,
    faEnvelope
  );
};

export default addIconsFar;
