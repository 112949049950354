import { useMatch, useParams } from 'react-router';

import type { QOptions, QResult } from 'services/api/api.types';
import type { DamUser} from 'services/api/domain/damUser';
import { useDamUserByEmail } from 'services/api/domain/damUser';
import { routes } from 'services/router/routes';

type UseUserData = (options?: QOptions<DamUser>) => {
  isUserSingle: boolean;
  id?: string;
  view?: string;
  query: QResult<DamUser>;
  refetch: () => void;
};

export const useUserData: UseUserData = (options = { enabled: false }) => {
  const isUserSingle = useMatch(routes.investors.users.single._path) !== null;
  const { id, view } = useParams();
  const query = useDamUserByEmail({ email: id || '', type: 'admin' }, options);

  return {
    isUserSingle,
    id,
    view,
    query,
    refetch: query.refetch
  };
};
