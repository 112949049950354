import { Tabs } from 'antd';
import tw, { styled, css } from 'twin.macro';

// eslint-disable-next-line import/prefer-default-export
export const StyledNavigation = styled(Tabs)(() => [
  css`
    ${tw`mb-0 `};

    & .ant-tabs-nav {
      ${tw`mb-0`};
    }

    & .ant-tabs-tab {
      ${tw`py-8`};
    }

    & .ant-tabs-tab .ant-tabs-tab-btn {
      ${tw`font-600`};

      & svg {
        ${tw`mr-8`};
      }
    }
  `
]);
