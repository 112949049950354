import React from 'react';

import ReactDOM from 'react-dom/client';

import './styles/tailwind.prod.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/main.css';
import './styles/storylines.theme.css';
import App from 'components/global/app';
import reportWebVitals from 'reportWebVitals';
import intiI18n from 'services/i18n/i18n.init';
import initIcons from 'services/icons';

import '@fontsource/barlow/400.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';
import '@fontsource/barlow/700.css';
import '@fontsource/barlow/800.css';

intiI18n();
initIcons();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
