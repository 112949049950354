/* eslint-disable import/prefer-default-export */
import tw, { css, styled } from 'twin.macro';

export const Container = styled.div(() => [
  css`
    ${tw`px-24 pt-12 pb-6`}

    & .ant-breadcrumb {
      ${tw`font-600 m-0 text-13 `}

      & ol {
        ${tw` m-0  `}
      }

      & .breadcrumb-item {
        ${tw`text-neutral-500`}

        & a {
          ${tw`text-neutral-500`}

          &:hover {
            ${tw`text-primary `}
          }
        }
      }
    }

    & .anticon.anticon-down svg {
      ${tw`text-11`}
      transform: translateY(-1px);
    }

    & .ant-breadcrumb-separator {
      ${tw`mx-8 text-primary text-12`}
    }
  `
]);
