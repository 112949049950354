import countries from 'i18n-iso-countries';

import { useLanguage } from '@dam/shared';

type GetCountraNameHandler = (country?: string) => string | undefined;

type UseCountryName = () => GetCountraNameHandler;

export const useCountryName: UseCountryName = () => {
  const language = useLanguage();

  const handleGetName: GetCountraNameHandler = name => {
    if (!name) {
      return undefined;
    }

    return countries.getName(name, language);
  };

  return handleGetName;
};
