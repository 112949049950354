/* eslint-disable import/prefer-default-export */
import { Layout } from 'antd';
import tw, { css, styled } from 'twin.macro';

const { Header, Sider } = Layout;

export const Container = styled(Layout)(() => [
  css`
    &.ant-layout {
      ${tw`h-screen bg-neutral-0 p-0`};
    }
  `
]);

export const HeaderContainer = styled(Header)(() => [
  css`
    &.ant-layout-header {
      ${tw`bg-primary-bg p-0 relative h-50 shadow-lg`};
      line-height: unset;
    }
  `
]);

export const SidebarContainer = styled(Sider)(() => [
  css`
    &.ant-layout-sider {
      ${tw`bg-neutral-100`};

      & .ant-layout-sider-trigger {
        ${tw`bg-neutral-100 hover:bg-neutral-200`};
        bottom: unset;

        & .ant-btn {
          ${tw`w-full`};

          & svg {
            ${tw`transition-all`};
          }
        }
      }

      &.ant-layout-sider-collapsed .ant-layout-sider-trigger .ant-btn svg {
        transform: rotate(180deg);
      }

      & .ant-layout-sider-children {
        ${tw`relative`};
      }

      & .scroll {
        ${tw`h-full overflow-x-hidden`};
      }
    }
  `
]);

export const ContentContainer = styled(Layout)(() => [
  css`
    ${tw`bg-neutral-0`};
  `
]);
